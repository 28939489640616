import React from 'react'

// Components
import Image from '../../../components/Image'
import useDataLayer from '../../../hooks/useDataLayer/dataLayerBody'

// Styles
import { ButtonLink } from '../../../styles/link'

const Documentos = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='bg-white py-5 pb-md-2 py-lg-5'>
      <div className='container'>
        <header className='text-center mb-2'>
          <h3 className='text-gray-700 fs-20 lh-24 mb-3 mb-md-2 fw-600'>Acesso à informação</h3>
          <p className='text-gray-700 fs-14 lh-18'>Acesse nossos documentos de Compliance e Informe de Cotas</p>
        </header>
        <div className='row justify-content-center align-items-center'>
          <div className='col-12 d-flex justify-content-center px-0'>
            <div className='col-6 col-lg-4 mx-auto mx-lg-0 mb-3 mb-lg-4 mb-md-4'>
              <ButtonLink
                className='btn text-uppercase bg-white text-orange-medium fs-14 w-100 fw-600'
                href='/acesso-a-informacao?category=informe-de-cotas'
                target='_blank'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_09',
                    section_name: 'Acesso à informação',
                    element_name: 'Informe de Cotas',
                    element_action: 'click button',
                    redirect_url: 'www.interasset.com.br/acesso-a-informacao?category=informe-de-cotas',
                  })
                }}
              >
                Informe de Cotas
              </ButtonLink>
            </div>
            <div className='col-6 col-lg-4 mx-auto mx-lg-0 mb-4'>
              <ButtonLink
                className='btn text-uppercase bg-white text-orange-medium fs-14 w-100 fw-600'
                href='/acesso-a-informacao?category=compliance'
                target='_blank'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_09',
                    section_name: 'Acesso à informação',
                    element_name: 'Documentos de Compliance',
                    element_action: 'click button',
                    redirect_url: 'www.interasset.com.br/acesso-a-informacao?category=compliance',
                  })
                }}
              >
                Documentos de Compliance
              </ButtonLink>
            </div>
          </div>
          <div className='col-5 col-md-2 mb-4 mb-lg-5 mt-2 mt-lg-4'>
            <Image name='LogoAnbimaRecursos' alt='Logo Anbima Recursos' className='border rounded-5' />
          </div>
          <div className='col-5 col-md-2 mb-4 mb-lg-5 mt-2 mt-lg-4'>
            <Image name='LogoAnbimaPatrimonio' alt='Logo Anbima Patrimônio' className='border rounded-5' />
          </div>
          <div className='col-5 col-md-2 mb-4 mb-lg-5 mt-2 mt-lg-4'>
            <a
              href='https://comoinvestir.anbima.com.br'
              target='_blank'
              rel='noreferrer'
              title='Como Investir'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_09',
                  section_name: 'Acesso à informação',
                  element_name: 'Logo Como Investir',
                  element_action: 'click button',
                  redirect_url: 'https://comoinvestir.anbima.com.br',
                })
              }}
            >
              <Image name='LogoComoInvestir' alt='Logo Como Investir' />
            </a>
          </div>
          <div className='col-12 text-center text-gray-600'>
            <p className='fs-14 lh-18 mb-4'>
              O propósito deste site é divulgar informações e oferecer transparência à gestão executada pela Inter Asset, não significando
              oferta de venda de fundos de investimento.
            </p>
            <p className='fs-14 lh-18 mb-0'>
              Ao investidor é recomendada a leitura do formulário de informações complementares, lâmina de informações essenciais, se houver e
              o regulamento antes de investir. Lembramos que rentabilidade obtida no passado não representa garantia de resultados
              futuros. A rentabilidade divulgada não é líquida de impostos. Fundos de investimento não contam com a garantia do administrador,
              do gestor, de qualquer mecanismo de seguro ou Fundo Garantidor de Crédito – FGC.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Documentos
