import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

interface IImage {
  name: string;
  alt: string;
  className?: string;
}

const Image: React.FC <IImage> = ({ name, alt, className }: IImage): React.ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      LogoAnbimaPatrimonio: file(relativePath: { eq: "autor-regulacao-anbina-patrimonio.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      LogoAnbimaRecursos: file(relativePath: { eq: "autor-regulacao-anbina-recursos.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      LogoComoInvestir: file(relativePath: { eq: "logo-como-investir.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HistoricoDeConsistencia: file(relativePath: { eq: "historico-de-consistencia.png" }) {
        childImageSharp {
          fluid(maxWidth: 470) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerRelatoriosDesktop: file(relativePath: { eq: "banner-relatorios-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerRelatoriosMobile: file(relativePath: { eq: "banner-relatorios-Mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      NossoTimeCaminhaComVoce: file(relativePath: { eq: "nosso-time-caminha-com-voce.png" }) {
        childImageSharp {
          fluid(maxWidth: 1900, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      LogoInterAssetNewBranding: file(relativePath: { eq: "logo-inter-asset-new-branding.png" }) {
        childImageSharp {
          fluid(maxWidth: 128, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data[name].childImageSharp.fluid} alt={alt} className={className} />
}

export default Image
